import { lazy } from "react";
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));

const CreateEdit = lazy(() => import("@/components/data/CreateEdit"));
const CreateEditEvents = lazy(() =>
  import("@/components/data/CreateEditEvents")
);
const Sandwiches = lazy(() => import("@/pages/servingPlates/Sandwiches"));
const PrivateEventPage = lazy(() =>
  import("../pages/privateEvents/PrivateEventPage")
);
const Salads = lazy(() => import("@/pages/servingPlates/Salads"));
const CreateEditSandwich = lazy(() =>
  import("@/components/data/CreateEditSandwich")
);
const MainPagesRoutes = [
  {
    path: "/servingPlates/:type/salads", // :type will be 'dairy' or 'meat'
    element: <Salads />, // This page will handle the filtering based on the type
    children: [
      {
        path: "createEdit",
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/servingPlates/:type/sandwiches",
    element: <Sandwiches />,
    children: [
      {
        path: "createEditSandwich",
        element: (
          <ProtectedRoute>
            <CreateEditSandwich />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/catering/:eventId",
    element: <PrivateEventPage />,
    children: [
      {
        path: "createEditEvent",
        element: (
          <ProtectedRoute>
            <CreateEditEvents />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default MainPagesRoutes;
