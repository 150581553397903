import { lazy } from "react";
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));

const DiaryPage = lazy(() => import("@/pages/servingPlates/diary/DiaryPage"));
const CreateEdit = lazy(() => import("@/components/data/CreateEdit"));

const Pasta = lazy(() => import("@/pages/servingPlates/Pasta"));

const DairyRoutes = [
  {
    path: "/servingPlates/dairy",
    element: <DiaryPage />,
    children: [
      {
        path: "createEdit",
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },

  {
    path: "/servingPlates/dairy/pasta", // New child route for Pasta
    element: <Pasta />, // The component to render for this route
    children: [
      {
        path: "createEdit", // Route for creating and editing pasta
        element: (
          <ProtectedRoute>
            <CreateEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default DairyRoutes;
